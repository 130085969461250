import { Context, Middleware } from '@nuxt/types'
import { isPrivateRoute } from '~/shared/utils/helpers'

/**
 * Проверка доступа к роуту на основании текущих прав
 */
const checkAccess: Middleware = ({ $tstore, route, redirect }: Context) => {
  const user = $tstore.auth.authorizedUser
  if (isPrivateRoute(route)) {
    if (user === null) {
      redirect(`/`, {
        backUrl: route.fullPath
      })
      return
    }

    const hasAccess = user.allowedRoutes.reduce((initial, rgx) => {
      return initial || rgx.test(route.path)
    }, false)

    if (!hasAccess) {
      redirect('/')
    }
  }
}

export default checkAccess
