import { AxiosResponse } from 'axios'
import { ApiService } from '~/shared/api'
import { getList } from '~/shared/utils/decorators'
import { ServiceHasList } from '~/shared/interfaces'

// eslint-disable-next-line
interface AccountResumesSubscriptionsService extends ServiceHasList {}

// @ts-ignore
@getList

// eslint-disable-next-line no-redeclare
class AccountResumesSubscriptionsService extends ApiService {
  protected route: string = 'subscriptions'

  public async changeStatusSubscribe<T>(id: string, status: string): Promise<AxiosResponse<T>> {
    return await this.requestService.request<T>({
      url: 'api/v1/yavagro/subscriptions/status',
      method: 'PATCH',
      data: {
        id,
        status
      }
    })
  }

  public async deleteSubscribe<T>(id: string): Promise<AxiosResponse<T>> {
    return await this.requestService.request<T>({
      url: '/api/v1/yavagro/subscriptions',
      method: 'DELETE',
      data: {
        id
      }
    })
  }

  public async readSubscribe<T>(id: string): Promise<AxiosResponse<T>> {
    return await this.requestService.request<T>({
      url: '/api/v1/yavagro/subscriptions/read',
      method: 'PATCH',
      data: {
        id
      }
    })
  }
}

export default AccountResumesSubscriptionsService
