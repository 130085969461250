import { defineComponent, computed, PropType } from 'vue-demi'
import type { Theme, ThemeType, Sizes, Radius } from '@svoi-ui/interfaces/button'
import type { IconType } from '@svoi-ui/interfaces/icon'
import { isString } from '@svoi-ui/shared/utils/guards'

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const BaseButtonComponent = defineComponent({
  name: 'BaseButton',
  props: {
    name: {
      type: String,
      default: null
    },
    theme: {
      type: String as PropType<Theme | string>,
      default: 'qiwi',
      validator: (value: unknown) => {
        return isString(value) && ['qiwi', 'figs', 'banana', 'chilli', 'potato'].includes(value)
      }
    },
    themeType: {
      type: String as PropType<ThemeType | string>,
      default: 'default',
      validator: (value: unknown) => {
        return isString(value) && ['default', 'light', 'outline'].includes(value)
      }
    },
    size: {
      type: String as PropType<Sizes | string>,
      default: 'xxl',
      validator: (value: unknown) => {
        return isString(value) && ['xxl', 'xl', 'l', 'm', 's', 'xs'].includes(value)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconLeft: {
      type: [String, Object] as PropType<IconType>,
      default: null
    },
    iconRight: {
      type: [String, Object] as PropType<IconType>,
      default: null
    },
    borderRadius: {
      type: String as PropType<Radius | string>,
      default: 'big',
      validator: (value: unknown) => {
        return isString(value) && ['big', 'medium'].includes(value)
      }
    },
    tabindex: {
      type: Number,
      default: 0
    }
  }
})

export const useBaseButton = (
  props: Readonly<{
    theme: string
    themeType: string
    size: string
    name: string
    disabled: boolean
    borderRadius: string
  }>
) => {
  const classes = computed(() => {
    return {
      [`-theme-${props.theme}`]: true,
      [`-theme-type-${props.themeType}`]: true,
      [`-size-${props.size}`]: true,
      [`-radius-${props.borderRadius}`]: true,
      '-round': !props.name,
      '-disabled': props.disabled
    }
  })

  const iconSize = computed(() => {
    switch (props.size) {
      case 'xxl':
        return 'l'
      case 'xl':
        return 'm'
      case 'l':
        return 'm'
      case 'm':
        return 's'
      case 's':
        return 's'
      case 'xs':
        return 'xs'
      default:
        return 'xs'
    }
  })

  return { classes, iconSize }
}
