import { ApiService } from '~/shared/api'
import { ResumesFilterModel } from '~/model/Resumes'
import { IResumesFiltersParams } from '~/interfaces/Filters'

export default class ResumesFiltersService extends ApiService {
  readonly url = 'api/v1/yavagro/resumes/filters'

  public async getResumesFilters<T>(queryParams: IResumesFiltersParams): Promise<ResumesFilterModel[]> {
    const res: any = await this.requestService.request<T>({
      url: this.url,
      params: queryParams
    })

    return res.data.data
  }
}
