
import { defineComponent, PropType } from 'vue-demi'
import { SvoiTooltippedIcon } from '@svoi-ui/components/icon'
import type { Target } from '@svoi-ui/interfaces/button'
import { isString } from '@svoi-ui/shared/utils/guards'
import { BaseButtonComponent, useBaseButton } from './BaseButton'
import BaseLink from './BaseLink.vue'

export default defineComponent({
  name: 'SvoiButtonLink',
  components: { SvoiTooltippedIcon, BaseLink },
  props: {
    ...BaseButtonComponent.props,
    ...BaseLink.props,
    target: {
      type: String as PropType<Target | string>,
      default: '_self',
      validator: (value: unknown) => {
        return isString(value) && ['_blank', '_parent', '_self', '_top'].includes(value)
      }
    }
  },
  setup(props) {
    const { classes, iconSize } = useBaseButton(props)

    return { classes, iconSize }
  },
  methods: { isString }
})
