import { CompanyInfoModel, SocialNetworkModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { BaseModel } from '~/shared/model'
import { roles } from '~/shared/const'
import type { UnknownObject } from '~/shared/interfaces'
import type { RoleId } from '~/interfaces/User'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { IntegrationsInterface } from '~/interfaces/User/Integrations'

const defaultRole: RoleId = 'student'

export default class UserModel extends BaseModel {
  public id: string = ''
  public name: string = ''
  public firstname: string = ''
  public lastname: string = ''
  public middlename: string = ''
  public email: string = ''
  public phone: string = ''
  public dateOfBirth: string = ''
  public isSimpleFeedbacksEnabled: boolean = false
  public idForIntegrations: string = ''
  public attributes: UnknownObject = {}
  public roles: Set<RoleId> = new Set()
  public allowedRoutes: Array<RegExp> = []
  public socialNetworks: Array<SocialNetworkModel> = []
  public integrations: Array<IntegrationsInterface> = []
  public 'sf_company': CompanyInfoModel = new CompanyInfoModel({})

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['roles'])

      if (isUnknownObject(data.profile) && isUnknownObject(data.profile.data)) {
        const profileData = data.profile.data

        if (isUnknownObject(profileData.additional_info)) {
          fillOwnProperties(this, profileData.additional_info)
          this.dateOfBirth = String(profileData.additional_info.dob)
        }

        if (isUnknownObject(profileData.sf) && isUnknownObject(profileData.sf.attributes)) {
          this.attributes = profileData.sf.attributes
        }
      }

      if (Array.isArray(data.users_socials)) {
        this.socialNetworks = data.users_socials.map(i => new SocialNetworkModel(i))
      }

      if (Array.isArray(data.users_roles)) {
        data.users_roles.forEach(r => {
          if (roles[r] !== undefined) {
            this.roles.add(r)
            this.allowedRoutes = this.allowedRoutes.concat(roles[r].allowedRoutes)
          }
        })
      }

      if (this.roles.size === 0) {
        this.roles.add(defaultRole)
        this.allowedRoutes = roles[defaultRole].allowedRoutes
      }
    }
  }
}
