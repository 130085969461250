import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { ResumesFilterModel } from '~/model/Resumes'
import { ResumeCardModel } from '~/model/Cards/Resumes'

@Module({ namespaced: true, stateFactory: true, name: 'resumes' })
export class ResumesModule extends VuexModule {
  private resumesList: ResumeCardModel[] = []
  private resumesFilters: ResumesFilterModel[] = []
  private resumesFiltersUpdate: boolean = false

  @Mutation
  private mutationSetResumesList(resumesList: ResumeCardModel[]): void {
    this.resumesList = resumesList
  }

  @Mutation
  private mutationSetResumesFilters(resumesFilters: ResumesFilterModel[]): void {
    this.resumesFilters = resumesFilters
  }

  @Mutation
  private mutationSetResumesFiltersUpdate(param: boolean): void {
    this.resumesFiltersUpdate = param
  }

  @Action
  public setResumesList(resumesList: ResumeCardModel[]): void {
    this.context.commit('mutationSetResumesList', resumesList)
  }

  @Action
  public setResumesFilters(resumesFilters: ResumesFilterModel[]): void {
    this.context.commit('mutationSetResumesFilters', resumesFilters)
  }

  @Action
  public setResumesFiltersUpdate(param: boolean): void {
    this.context.commit('mutationSetResumesFiltersUpdate', param)
  }

  get ResumesList() {
    return this.resumesList
  }

  get ResumesFilters() {
    return this.resumesFilters
  }

  get ResumesFiltersUpdate() {
    return this.resumesFiltersUpdate
  }
}
