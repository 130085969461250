import https from 'https'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import axios, { AxiosError, AxiosProxyConfig, AxiosRequestConfig, AxiosResponse } from 'axios'
import Utils from '~/utils'
import { HttpMethod, RequestError } from '~/types/network'
import { RequestException } from '~/types/exceptions'
import { TypedStore } from '~/store/storeAccessor'
import { isUnknownObject } from '~/shared/utils/guards'

interface RequestOptions {
  proxy?: AxiosProxyConfig
  url: string
  baseURL?: string
  data?: any
  method?: HttpMethod
  params?: any
  cache?: boolean
  headers?: any
  auth?: any
}

export class RequestService {
  constructor(private axios: NuxtAxiosInstance, private tstore: TypedStore) {
    if (process && process.env?.NODE_ENV !== 'production') {
      this.axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
    }
  }

  /**
   * Преобразование ошибки аксиос в единый формат
   * @param e Оригинальное исключение от аксиос
   */
  private transformErrorToRequestError(e: AxiosError): RequestError {
    const result = new RequestError()

    result.url = Utils.getValue(e.config.url, result.url)
    result.method = Utils.getValue(e.config.method?.toUpperCase() as HttpMethod, result.method)
    result.headers = Utils.getValue(e.config.headers, result.headers)
    result.params = Utils.getValue(e.config.params, result.params)
    result.data = Utils.getValue(e.config.data, result.data)
    result.status = Utils.getValue(e.response?.status, result.status)
    result.statusText = Utils.getValue(e.response?.statusText, result.statusText)
    result.responseHeaders = Utils.getValue(e.response?.headers, result.responseHeaders)
    result.message = Utils.getAnyValue(result.message, e.response?.data?.error?.message, e.message)
    result.serverCode = Utils.getValue(e.response?.data?.error?.code, result.serverCode)

    const errors = e.response?.data?.data?.errors
    if (isUnknownObject(errors)) {
      Object.keys(errors).forEach(key => {
        const errorValue = errors[key]
        if (typeof errorValue === 'string') {
          result.validationErrors[key] = errorValue
        } else if (Array.isArray(errorValue)) {
          result.validationErrors[key] = errorValue.map(v => String(v))
        }
      })
    }

    return result
  }

  public async request<T>(options: RequestOptions, isNeedBaseCredintails = true): Promise<AxiosResponse<T>> {
    const opts: AxiosRequestConfig = { ...options }
    opts.method = opts.method ?? 'GET'
    if (process.env.NODE_ENV === 'production' && process.server) {
      opts.baseURL = process.env.ENVIRONMENT_STAND === 'prod' ? 'http://back' : 'http://back-svoevagro'
    }

    if (isNeedBaseCredintails) {
      opts.auth = {
        username: 'customer',
        password: 'customer'
      }
    }

    try {
      return await this.axios.request<T>(opts)
    } catch (error: unknown) {
      if (process.client) {
        this.tstore.notification.setNotification({
          text: 'Что-то пошло не так. Попробуйте перезагрузить страницу',
          type: 'error'
        })
      }
      if (axios.isAxiosError(error)) {
        throw new RequestException(this.transformErrorToRequestError(error))
      }
      throw error
    }
  }
}
