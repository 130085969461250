import { ApiService } from '~/shared/api'
import BaseRecommendationCardModel from '~/model/Cards/BaseRecommendationCardModel'

class NewsRecommendationsService extends ApiService {
  protected url: string = '/api/v1/yavagro/news/recommendations'
  protected urlLatest: string = '/api/v1/yavagro/news/latest'

  async getNewsRecommendations() {
    const url = `${this.url}`
    const res: any = await this.requestService.request({
      url
    })
    return Array.isArray(res.data.data) ? res.data.data.map(i => new BaseRecommendationCardModel(i)) : []
  }

  async getNewsRecommendationsLatest(id?: string, count: number = 10) {
    const url = `${this.urlLatest}`
    const res: any = await this.requestService.request({
      url,
      params: {
        exclude_ids: [id],
        per_page: count
      }
    })
    return Array.isArray(res.data.data) ? res.data.data.map(i => new BaseRecommendationCardModel(i)) : []
  }
}

export default NewsRecommendationsService
