
import { defineComponent, PropType } from 'vue-demi'
import { LinkTypes, LinkTypesEnum } from '@svoi-ui/interfaces/link-type'
import { isString } from '@svoi-ui/shared/utils/guards'

export default defineComponent({
  name: 'BaseLink',
  props: {
    link: {
      type: String,
      required: true
    },
    componentType: {
      type: String as PropType<LinkTypes | string>,
      default: LinkTypesEnum.nuxtLink,
      validator: (value: unknown) => {
        return isString(value) && [LinkTypesEnum.a.toString(), LinkTypesEnum.nuxtLink.toString()].includes(value)
      }
    }
  },
  setup() {
    return { LinkTypesEnum }
  }
})
