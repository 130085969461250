
import { defineComponent, PropType, computed, inject } from 'vue-demi'
import { SizesEnum, Sizes } from '@svoi-ui/interfaces/icon'
import { isString } from '@svoi-ui/shared/utils/guards'

export default defineComponent({
  name: 'SvoiIcon',
  props: {
    icon: {
      type: String,
      required: true,
      default: ''
    },
    size: {
      type: String as PropType<Sizes | string>,
      default: SizesEnum.m,
      validator(value: unknown) {
        return (
          isString(value) &&
          [
            SizesEnum.xs.toString(),
            SizesEnum.s.toString(),
            SizesEnum.m.toString(),
            SizesEnum.l.toString(),
            SizesEnum.full.toString()
          ].includes(value)
        )
      }
    }
  },
  emits: ['click'],
  setup(props) {
    const injectedAssets = inject<{ '@svoi-ui': { icons: Record<string, unknown> } }>('assets', {
      '@svoi-ui': { icons: {} }
    })

    const requireSprite = (name: string): string => {
      const injectedKeys = Object.keys(injectedAssets['@svoi-ui'].icons)
      if (injectedKeys.length) {
        const findKey = injectedKeys.find(i => i.includes(`${name}.svg`))
        if (findKey) {
          const value = injectedAssets['@svoi-ui'].icons[findKey]
          return isString(value) ? value : ''
        }

        return ''
      }

      return require(`@svoi-ui/assets/icons/${name}.svg`)
    }

    const iconData = computed(() => {
      const data = { sprite: '', icon: '' }
      const chunks = (props.icon as string).split('/')

      if (chunks.length === 2) {
        data.sprite = requireSprite(chunks[0].toLowerCase())
        data.icon = chunks[1]
      }

      return data
    })

    return { iconData }
  }
})
