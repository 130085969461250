import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { EventModule } from './modules/Cards/EventModule'
import { ProfessionsModule } from './modules/Cards/ProfessionsModule'
import { WorkCardsModule } from './modules/Cards/WorkCardsModule'
import { FutureProfModule } from './modules/Cards/FutureProfModule'
import { TagCloudModule } from './modules/Misc/TagCloudModule'
import { UniversityProgramsModule } from './modules/Cards/UniversityProgramsModule'
import { SubscribeBlockModule } from './modules/Misc/SubscribeBlockModule'
import { DirectionModule } from './modules/DirectionModule'
import { PageHistoryModule } from './modules/PageHistoryModule'
import { BookModule } from './modules/Cards/BookModule'
import { BookSortingModule } from './modules/Cards/BookSortingModule'
import { FilterModule } from './modules/Cards/FilterModule'
import { QuantumModule } from './modules/Cards/QuantumModule'
import { GrantsModule } from './modules/Cards/GrantsModule'
import { SchoolModule } from './modules/Cards/SchoolModule'
import { GrantsSlidesModule } from './modules/Slides/GrantsSlidesModule'
import { GrantsSortingModule } from './modules/Cards/GrantsSortingModule'
import { GrantsFaqModule } from './modules/Cards/GrantsFaqModule'
import { StatisticBlockModule } from './modules/Misc/StatisticBlockModule'
import { SearchModule } from './modules/Misc/SearchModule'
import { LayoutModule } from './modules/Misc/LayoutModule'
import { SchoolMenuModule } from './modules/Menus/SchoolMenuModule'
import { SchoolDirectionsModule } from './modules/Cards/SchoolDirectionsModule'
import { CompetitionsModule } from './modules/Cards/CompetitionsModule'
import { UniversityModule } from './modules/Pages/UniversityModule'
import { CollegeModule } from './modules/Pages/CollegeModule'
import { MarathonModule } from './modules/Pages/MarathonModule'
import { ProfessionalEducationModule } from './modules/Pages/ProfessionalEducationModule'
import { ProfessionalEducationDirectionModule } from './modules/Pages/ProfessionalEducationDirectionModule'
import { UniversityDirectionModule } from './modules/Pages/UniversityDirectionModule'
import {
  AccountResumesSubscriptionsModule,
  ConferencesModule,
  DigitalProfilesFiltersModule,
  PartnerModule,
  ResumesModule
} from './modules'
import { CourseModule } from './modules/Pages/CourseModule'
import { RegionModule } from './modules/Pages/RegionModule'
import { SchoolchildrenModule } from './modules/Pages/SchoolchildrenModule'
import { RegionLocationModule } from './modules/RegionLocation/RegionLocationModule'
import { CollegeDirectionModule } from '~/store/modules/Pages/CollegeDirectionModule'
import { VacanciesModule } from '~/store/modules/Vacancies/VacanciesModule'
import { AmbassadorsModule } from '~/store/modules/Ambassadors/AmbassadorsModule'
import { StudentsModule } from '~/store/modules/Pages/StudentsModule'
import { NotificationModule } from '~/store/modules/NotificationModule'
import { DigitalProfileModule } from '~/store/modules/DigitalProfileModule'
import { FilterNewsModule } from '~/store/modules/FilterNewsModule'
import { CareerNavigatorModule } from '~/store/modules/CareerNavigator/CareerNavigator'
import {
  TestingModule,
  EmployerModule,
  EmployersTasksModule,
  CareerRoutesModule,
  NewsModule,
  AuthModule,
  AccountModule,
  SocialModule,
  GlobalSearchModule,
  PageMetadataModule
} from '~/store/modules'

Vue.use(Vuex)

const store = () =>
  new Store({
    state: {},
    modules: {
      auth: AuthModule,
      news: NewsModule,
      search: SearchModule,
      school: SchoolModule,
      conferences: ConferencesModule,
      competitions: CompetitionsModule,
      tagcloud: TagCloudModule,
      subscribeBlock: SubscribeBlockModule,
      professions: ProfessionsModule,
      workCards: WorkCardsModule,
      futureProf: FutureProfModule,
      direction: DirectionModule,
      digitalProfilesFilters: DigitalProfilesFiltersModule,
      universityPrograms: UniversityProgramsModule,
      book: BookModule,
      marathon: MarathonModule,
      bookSorting: BookSortingModule,
      filter: FilterModule,
      statisticBlock: StatisticBlockModule,
      quantums: QuantumModule,
      grants: GrantsModule,
      grantsSlides: GrantsSlidesModule,
      grantsSorting: GrantsSortingModule,
      grantsFaq: GrantsFaqModule,
      schoolMenu: SchoolMenuModule,
      schoolDirections: SchoolDirectionsModule,
      university: UniversityModule,
      college: CollegeModule,
      collegeDirection: CollegeDirectionModule,
      universityDirection: UniversityDirectionModule,
      professionalEducation: ProfessionalEducationModule,
      professionalEducationDirection: ProfessionalEducationDirectionModule,
      course: CourseModule,
      pageHistory: PageHistoryModule,
      employer: EmployerModule,
      employersTasks: EmployersTasksModule,
      pageMetadata: PageMetadataModule,
      event: EventModule,
      region: RegionModule,
      regionLocation: RegionLocationModule,
      vacancies: VacanciesModule,
      ambassadors: AmbassadorsModule,
      partner: PartnerModule,
      students: StudentsModule,
      notification: NotificationModule,
      digitalProfile: DigitalProfileModule,
      schoolchildren: SchoolchildrenModule,
      filterNews: FilterNewsModule,
      account: AccountModule,
      social: SocialModule,
      testing: TestingModule,
      globalSearch: GlobalSearchModule,
      layout: LayoutModule,
      careerNavigator: CareerNavigatorModule,
      careerRoutes: CareerRoutesModule,
      resumes: ResumesModule,
      resumesSubscriptions: AccountResumesSubscriptionsModule
    },
    getters: {},
    mutations: {},
    actions: {}
  })

export default store
