import { ApiService } from '~/shared/api'
import { getList } from '~/shared/utils/decorators'
import { ServiceHasList } from '~/shared/interfaces'
import { RequestService } from '~/services/RequestService'
import { ResumesFiltersService } from '~/services/Resumes/index'

// eslint-disable-next-line
interface ResumesService extends ServiceHasList {}

// @ts-ignore
@getList

// eslint-disable-next-line no-redeclare
class ResumesService extends ApiService {
  protected route: string = 'resumes'

  filtersService: ResumesFiltersService

  constructor(requestService: RequestService) {
    super(requestService)
    this.filtersService = new ResumesFiltersService(requestService)
  }
}

export default ResumesService
