import { Form } from '~/shared/api'
import type { InputParams as SubscribeInputParams } from '~/interfaces/Subscribe'

export default class SubscribeResumesForm extends Form {
  public email: string = ''
  public 'subscription[type]': string = 'resumes'
  public 'subscription[params][filter][region]': string = ''
  public 'subscription[params][filter][experience]': string = ''
  public 'subscription[params][filter][residences]': string = ''
  public 'subscription[params][filter][position]': string = ''
  public 'subscription[params][filter][employment_types]': string = ''
  public 'subscription[params][filter][education_levels]': string = ''
  public 'subscription[params][filter][end_of_education]': string = ''
  public 'subscription[params][filter][salary]': string = ''

  beforeSerialize() {
    Object.getOwnPropertyNames(this).forEach(prop => {
      if (this[prop] === '') {
        this.ignoreKeys.push(prop)
      }
    })
  }

  fillParams(params: SubscribeInputParams): void {
    const props = Object.getOwnPropertyNames(this)
    Object.keys(params).forEach(key => {
      if (props.includes(`subscription[params][filter][${key}]`)) {
        this[`subscription[params][filter][${key}]`] = params[key]
      }

      if (props.includes(`subscription[params][${key}]`)) {
        this[`subscription[params][${key}]`] = params[key]
      }

      if (props.includes(`subscription[${key}]`)) {
        this[`subscription[${key}]`] = params[key]
      }
    })
  }
}
