
import {
  defineComponent,
  computed,
  useRoute,
  ref,
  useRouter,
  useContext,
  onMounted,
  watch
} from '@nuxtjs/composition-api'
import HeaderEcosystem from '~/components/Header/HeaderEcosystem.vue'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import SearchWithButton from '~/components/UI/SearchWithButton.vue'
import RoundButton from '~/components/UI/RoundButton.vue'
import { getHeaderMainLinks } from '~/shared/const'
import { isUnknownObject } from '~/shared/utils/guards'
import type { MenuItem } from '~/interfaces/Header'

export default defineComponent({
  name: 'BottomMenu',
  components: {
    HeaderEcosystem,
    AppLazyImage,
    SearchWithButton,
    RoundButton
  },
  emits: ['on-side-menu-click'],
  setup(_, { emit }) {
    const $route = useRoute()
    const $router = useRouter()
    const context = useContext()

    const mainLinks = ref<Array<MenuItem>>([])

    const searchValue = computed({
      get: () => context.$tstore.globalSearch.searchQuery,
      set: value => context.$tstore.globalSearch.setQuery(value)
    })

    const getMainLinks = () => {
      const region = context.$services.geolocation.getCookieRegion()
      mainLinks.value = getHeaderMainLinks(
        isUnknownObject(region) && typeof region.slug === 'string' ? region.slug : ''
      )
    }

    onMounted(() => getMainLinks())

    watch(() => context.$tstore.regionLocation.RegionLocationItem, getMainLinks, { immediate: true, deep: true })

    const isShowGeolocation = computed(() => {
      return (
        $route.value.name === 'looking-for-job-slug' ||
        $route.value.name === 'vacancies-group' ||
        $route.value.name === 'vacancies-group-region'
      )
    })

    const onSearch = () => {
      const items = context.$tstore.globalSearch.items
      for (const data of Object.values(items)) {
        if (data.length > 0) {
          $router.push(data[0].link)
          context.$tstore.globalSearch.setQuery('')
          break
        }
      }
    }

    const toggleMobileMenu = () => {
      emit('on-side-menu-click')
    }

    const authButtonClick = () => {
      if (context.$tstore.auth.isLogged) {
        $router.push('/account')
      } else {
        const backUrl =
          $route.value.query.backUrl && $route.value.query.backUrl !== 'undefined'
            ? $route.value.query.backUrl.toString()
            : '/account'
        context.$tstore.auth.setShowModalAuth({ show: true, backUrl })
      }
    }

    const showModalRegionChange = () => {
      context.$tstore.regionLocation.setIsShowModalRegionConfirmMobile(
        !context.$tstore.regionLocation.ShowModalRegionConfirmMobile
      )
    }

    return {
      searchValue,
      mainLinks,
      isShowGeolocation,
      onSearch,
      toggleMobileMenu,
      showModalRegionChange,
      authButtonClick
    }
  }
})
