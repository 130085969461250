
import BaseUiKit from '@rshb/rshb-vue-kit/src/components/base/BaseUiKit.vue'
import LazyHydrate from 'vue-lazy-hydration'

import Seo from '~/utils/seo.ts'
import Cookie from '@/components/Cookie/Cookie'
import RegionLocation from '@/components/RegionLocation/RegionLocation'
import ModalAuth from '~/components/Modal/ModalAuth.vue'
import BaseYavagroToastWrapper from '~/components/Notifications/BaseYavagroToastWrapper.vue'

export default {
  components: {
    RegionLocation,
    Cookie,
    LazyHydrate,
    BaseUiKit,
    ModalAuth,
    BaseYavagroToastWrapper,
    Header: () => import('~/components/Header/Header.vue'),
    Footer: () => import('~/components/Footer/Footer.vue'),
    BaseRabotaAgroBottomNavigationMenu: () =>
      import('~/components/BottomMobileMenu/BaseRabotaAgroBottomNavigationMenu.vue'),
    ModalContainer: () => import('~/components/Misc/ModalContainer.vue'),
    ModalRegionLocationContainer: () => import('~/components/Misc/ModalRegionLocationContainer.vue')
  },
  data() {
    return {
      headerPositon: 140,
      globalSearch: '',
      searchRegionValue: '',

      menuTabs: {
        activeTab: '/',
        items: [
          {
            icon: 'home',
            name: 'Главная',
            ref: '/'
          },
          {
            icon: 'menu-cat',
            name: 'Меню',
            ref: 'menu',
            openMenu: true,
            sideMenuItems: [
              {
                title: 'Главная',
                ref: ''
              },
              {
                title: 'Вакансии',
                ref: 'vacancies'
              },
              {
                title: 'Резюме',
                ref: 'resume-list'
              },
              {
                title: 'Вузы',
                ref: 'universities'
              },
              {
                title: 'Колледжи',
                ref: 'colleges'
              },
              {
                title: 'Калькулятор ЕГЭ',
                ref: 'calculator-ege'
              },
              {
                title: 'Журнал',
                ref: 'news'
              },
              {
                title: 'Агроклассы',
                ref: 'https://agroclasses.svoevagro.ru/?utm_source=svoevagro'
              },
              {
                title: 'Для работодателей',
                ref: 'for-employers'
              },
              {
                title: 'Карьерные маршруты',
                ref: 'career-routes'
              },
              {
                title: 'Поддержка',
                ref: 'support'
              }
            ]
          },
          {
            isGap: true
          },
          {
            icon: 'user',
            name: 'Профиль',
            ref: '/account',
            openModal: !this.$tstore.auth.isLogged
          },
          {
            icon: 'menu',
            name: 'Сервисы',
            ref: 'menu',
            openServices: true
          }
        ]
      },

      firstfooterMenus: [
        {
          name: 'О нас',
          items: [
            {
              name: 'О Россельхозбанке',
              url: 'https://www.rshb.ru/about/',
              isExternal: true
            }
          ]
        }
        // {
        //   name: 'Банковские продукты',
        //   items: [
        //     {
        //       name: 'Для юридических лиц',
        //       url: '/application-for-bank-product',
        //     },
        //     {
        //       name: 'Для физических лиц',
        //       url: '/rshb',
        //     }
        //   ]
        // }
      ],

      secondfooterMenus: [
        {
          name: 'Пользователям',
          items: [
            {
              name: 'Вузы',
              url: '/universities'
            },
            {
              name: 'Журнал',
              url: '/news'
            },
            {
              name: 'Библиотека',
              url: '/books'
            },
            {
              name: 'Поддержка',
              url: '/support'
            }
          ]
        },
        {
          name: 'По всем вопросам',
          items: [
            {
              name: 'обращаться по адресу </br>svoevagro-help@rshb.ru',
              url: 'mailto:svoevagro-help@rshb.ru',
              isExternal: true
            }
          ]
        }
      ],
      footerSocial: [
        {
          icon: '/icons/social/vk.svg',
          url: '#'
        },
        {
          icon: '/icons/social/ok.svg',
          url: '#'
        }
      ],
      footerBottomMenu: [
        {
          name: 'Политика обработки персональных данных',
          url: 'https://www.rshb.ru/pd-policy/',
          isExternal: true
        },
        {
          name: 'Пользовательское соглашение',
          url: '/documents/terms.pdf',
          isExternal: true
        },
        {
          name: 'Согласие на обработку персональных данных',
          url: '/documents/Согласие_на_обработку_ПДн_РСХБ_СКОЛКОВО_ЦО.pdf',
          isExternal: true
        }
      ],
      searchItems: [],
      showNoResults: false,
      windowWidth: 0
    }
  },

  head() {
    return Seo.getHeaderMeta(this.$tstore.pageMetadata.getSeoMetadata)
  },

  computed: {
    isGeolocationPage() {
      return (
        this.$tstore.regionLocation.ShowModalRegionConfirmMobile &&
        (this.$route.name === 'looking-for-job-slug' ||
          this.$route.name === 'vacancies-group' ||
          this.$route.name === 'vacancies-group-region')
      )
    },
    getTopPosition() {
      const path = this.$route.path
      const account = /^\/account\/candidate\/.*/

      if (path === '/' || path === '/students' || path === '/students/ambassadors' || account.test(path)) {
        if (this.windowWidth <= 1439 && this.windowWidth > 720) {
          return '0'
        }
        if (this.windowWidth < 720) {
          return '0'
        }
        return '-120px'
      }
      if (this.windowWidth <= 1439 && this.windowWidth > 720) {
        return '0'
      }
      if (this.windowWidth < 720) {
        return '0'
      }
      return '-56px'
    },
    getCopyrightText() {
      return `© 2000-${new Date().getFullYear()} АО «Россельхозбанк»</br> Генеральная лицензия Банка России № 3349 от 12 августа 2015`
    },
    isShowHeader() {
      return !this.$tstore.layout.hideHeader
    },
    isShowFooter() {
      return !this.$tstore.layout.hideFooter
    }
  },
  watch: {
    async searchRegionValue() {
      if (this.searchRegionValue.length >= 3) {
        const regions = await this.$services.geolocation.getSearchRegions(this.searchRegionValue)

        if (Array.isArray(regions)) {
          this.$tstore.regionLocation.setRegionLocationList(regions)
        }
      } else {
        const regions = await this.$services.geolocation.getRegions()

        if (regions) {
          this.$tstore.regionLocation.setRegionLocationList(regions)
        }
      }
    },
    '$tstore.regionLocation.ShowModalRegionChange'() {
      if (this.$tstore.regionLocation.ShowModalRegionChange) {
        document.addEventListener('click', this.clickWithout)
      } else {
        document.removeEventListener('click', this.clickWithout)
      }
    }
  },

  mounted() {
    const el = document.querySelector('.header-container')
    const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('pinned', e.intersectionRatio < 1), {
      threshold: [1]
    })

    if (
      // eslint-disable-next-line nuxt/no-env-in-hooks
      process.client &&
      !this.$services.geolocation.getCookieRegion() &&
      this.$tstore.regionLocation.RegionLocationItem
    ) {
      if (window.innerWidth >= 1439) {
        this.$tstore.regionLocation.setIsShowModalRegionConfirm(true)
      } else {
        this.$tstore.regionLocation.setIsShowModalRegionConfirmMobile(true)
      }
    }

    observer.observe(el)

    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },

  beforeMount() {
    this.$tstore.pageHistory.setPageHistoryNumber(window.history.length)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    menuTabClick(tab) {
      if (tab.openModal && !this.$tstore.auth.isLogged) {
        this.$tstore.auth.setShowModalAuth({ show: true })
      } else {
        this.$router.push(tab.ref)
      }
    },
    async onSearchInput(value) {
      this.globalSearch = value
      if (value.length > 2) {
        const vacancies = await this.getVacancies(value)
        const items = await this.$services.search.getSearch(value)

        this.searchItems =
          vacancies.length > 0
            ? {
                ...items,
                vacancies
              }
            : { ...items }
        if (!items.length) {
          this.showNoResults = true
        }
      } else {
        this.showNoResults = false
        this.searchItems = []
      }
    },
    onClickRegionItem(region) {
      this.$services.geolocation.setCookieRegion(region)
      this.$tstore.regionLocation.setIsShowModalRegionChange(false)
      this.$tstore.regionLocation.setRegionLocationItem(region)

      const queryParams = { ...this.$tstore.filterNews.getFilterUrlQuery, ...this.$route.query }
      delete queryParams.region
      delete queryParams['vacancies-cities']

      if (this.$route.name === 'looking-for-job-slug') {
        this.$router.push({ path: '/looking-for-job/' + region.slug })
      }

      if (this.$route.name.includes('vacancies') && !this.$route.params.id) {
        const pathRoute = region.slug === 'russia' ? '/vacancies' : `/vacancies/region-${region.slug}`
        this.$router.push({ path: pathRoute, query: queryParams })
      }

      if (this.$route.name.includes('resume-list') && !this.$route.params.id) {
        const pathRoute = `/resume-list?filter[region]=${region.slug}`
        this.$router.push({ path: pathRoute, query: {} })
      }
    },

    closeModalRegion() {
      this.$tstore.regionLocation.setIsShowModalRegionConfirmMobile(false)
      this.$tstore.regionLocation.setIsShowModalRegionChange(false)
    },

    onSuccessAnswer(region) {
      this.$services.geolocation.setCookieRegion(region)
      this.$tstore.regionLocation.setIsShowModalRegionConfirmMobile(false)

      if (this.$route.name === 'looking-for-job-slug' && region?.slug) {
        this.$router.push(`/looking-for-job/${region.slug}`)
      }

      if (this.$route.name.includes('vacancies')) {
        const pathRoute = region.slug === 'russia' ? '/vacancies' : `/vacancies/region-${region.slug}`
        this.$router.push(pathRoute)
      }
    },

    onNegativeAnswer() {
      this.$tstore.regionLocation.setIsShowModalRegionConfirmMobile(false)
      this.$tstore.regionLocation.setIsShowModalRegionChange(true)
    },

    onUpdateValue(value) {
      this.searchRegionValue = value
    },

    async getVacancies(value) {
      const vacanciesList = await this.$services.requestRest.getList('vacancies', {
        'sf:position_name_kw': value,
        size: 5
      })
      return vacanciesList.data || []
    },

    clickWithout(e) {
      const el = this.$refs.regionChange
      const target = e.target
      if (target !== el && !el.contains(target)) {
        this.$tstore.regionLocation.setIsShowModalRegionChange(false)
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
