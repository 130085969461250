import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'
import BaseRecommendationCardLabelModel from '~/model/Cards/BaseRecommendationCardLabelModel'

export default class BaseRecommendationCardModel extends BaseModel {
  slug: string = ''
  image: string = ''
  time: string = ''
  name: string = ''
  date: string = ''
  'updated_at': string = ''
  'created_at': string = ''
  views: number = 0
  likes: number = 0
  labels: Array<BaseRecommendationCardLabelModel> = []

  constructor(data: any) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.image) {
        this.image = new ImageService().cropImage(this.image, {
          width: 343,
          height: 164
        })
      }

      if (this.updated_at !== '') {
        this.date = new Date(this.updated_at.replace(' ', 'T')).toLocaleDateString('fr-CH')
      } else {
        this.date = new Date(this.created_at.replace(' ', 'T')).toLocaleDateString('fr-CH')
      }
    }
  }
}
