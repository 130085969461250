import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ResumesSubscriptionsCardModel } from '~/model/Cards/ResumesSubscriptionsCard'

@Module({ namespaced: true, stateFactory: true, name: 'resumesSubscriptions' })
export default class extends VuexModule {
  private resumesSubscriptionsList: ResumesSubscriptionsCardModel[] = []

  @Mutation
  private mutationSetResumesSubscriptionsList(list: ResumesSubscriptionsCardModel[]): void {
    this.resumesSubscriptionsList = list
  }

  @Action
  public setResumesSubscriptionsList(list: ResumesSubscriptionsCardModel[]): void {
    this.context.commit('mutationSetResumesSubscriptionsList', list)
  }

  get ResumesSubscriptionsList() {
    return this.resumesSubscriptionsList
  }
}
